.register-container {
    width: 100%;
    height: 100%;
    box-shadow: 0px 25px 60px rgba(9, 44, 99, 0.24);
    border-radius: 10px;
    // -webkit-app-region: drag;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4E7EC;
}

.register-space {
    // flex: 1 1 0;
    margin: 15px 0;
}

.register-header {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.register-header-button-container {
    padding: 14px 15px 0px 0px;
    // float: right;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    img {
        width: 16px;
        height: 16px;
        margin: 7px 7px 0 7px;
    }
}



.register-header-title {
    font-family: "Bold", sans-serif;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    height: 33px;
    margin-top: -4px;
}

.register-header-sub-title {
    margin-top: 3px;

    font-size: 16px;
    height: 22px;
    line-height: 22px;
    font-family: "Regular", sans-serif;
    color: rgba(0, 0, 0, 0.45)
}

.register-header {
    margin-left: -255px;
}

.register-rules-header-title {
    font-family: "Bold", sans-serif;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    height: 33px;
    margin-top: -4px;
    text-align: center;
    padding: 0 216px 0 30px;
}

.register-form {
    // padding-top: 20px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 80px;
    align-items: center;

    // flex-direction: row;
    .ant-input {
        height: 40px;
        width: 326px;
        font-size: 16px;
    }

    .ant-form-item-label>label {
        font-size: 16px;
        font-family: "Regular", sans-serif;
        color: #000000;
        height: 22px;
        line-height: 22px;
        margin-top: 9px;
        font-weight: 400;
        opacity: 1;
        display: inline-block;
        width: 80px;
        margin-right: 10px;
        text-align: right;
    }

    @media (max-width: 575px) {

        .ant-form .ant-form-item .ant-form-item-label,
        .ant-form .ant-form-item .ant-form-item-control {
            flex: 0 0 auto;
        }
    }

    .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
    .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
    .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
    .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
        top: 58%
    }

    .ant-form-item-explain.ant-form-item-explain-error {
        color: #E60505;
        height: 20px;
        line-height: 20px;
    }
}

.register-button {
    height: 50px;
    width: 402px;
    margin-top: 0px;
    background: #105EBF;
    box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    opacity: 1;
    border-radius: 2px;
    font-family: "Regular", sans-serif;
    font-weight: 400;
    font-size: 18px;

    &:hover {
        background: #3A7ED2;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }

    &:active {
        background: #0250B1;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }
}


.rules-content {
    overflow-y: auto;
    width: 402px;
    height: 380px;
    position: absolute;
    left: 30px;
    top: 91px;
    background: rgba(63, 89, 174, 0.05);
    font-size: 14px;
    font-family: "Regular", sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}

.rules-button {
    border: 1px solid #3F59AE;
    background: #FFFFFF;
    width: 70px;
    height: 28px;
    position: absolute;
    left: 196px;
    top: 488px;
    font-size: 14px;
    font-family: "Regular", sans-serif;
    font-weight: 400;
    color: #3F59AE;
    border-radius: 2px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}


::-webkit-scrollbar {
    width: 17px;
    background: #F1F1F1;
    border: 1px solid #E9E9E9;
    opacity: 1;
}

::-webkit-scrollbar-thumb {
    width: 13px;
    height: 171px;
    background: #C1C1C1;
    opacity: 1;
}


.ant-checkbox {
    margin-right: 2px;
    height: 16px;
    width: 16px;
}

.ant-checkbox-wrapper {
    margin-top: 2px;

    span {
        font-size: 16px;
        font-family: "Regular", sans-serif;
        font-weight: 400;
        line-height: 22px;
        cursor: default;
    }
}

.rg-captcha {
    width: 130px;
    height: 40px;
    background: rgba(63, 89, 174, 0.2);
    border-radius: 2px;
    margin-left: 10px;
    font-size: 16px;
    font-family: "PingFangRegular", sans-serif;
    font-weight: 400;
    color: #105EBF;
    vertical-align: top;

    &:hover {
        background: rgba(63, 89, 174, 0.15);
    }

    &:active {
        background: rgba(63, 89, 174, 0.3);
    }
}

.contentinfo {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
}

.contentinfo::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
}

.infofont {
    font-weight: bold;
}

.registeraggrement {
    // width: 400px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 11px;

    span {
        font-size: 16px;
        height: 22px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        font-family: "Regular", sans-serif;
    }

    input {
        margin: 3px 0;
        width: 16px;
        height: 16px;
        vertical-align: top;
        border: 1px solid #D9D9D9;
        cursor: pointer;
        -webkit-app-region: no-drag;
    }

    .fuwuxieyi {
        color: #3F59AE;
        font-weight: 400;
    }
}

.register-container_fl {
    width: 55%;


    background: url(../images/login/login_fl_back.png) no-repeat;
    background-size: 100% 100%;
    padding: 34px;
    max-height: 655px;
    height: 655px;

}

.register-container_fr {
    width: 45%;
    min-width: 416px;
    height: 655px;

    background-color: #ffffff;
    max-height: 774px;
}

.register-container_fl_box {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-container_fl_box_center h3 {
    height: 53px;
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 53px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
}

.register-container_fl_top_img {
    width: 99px;
    height: 84px;

    img {
        width: 100%;
    }
}

.register-container_fl_center_img {


    width: 354px;
    height: 400px;
    img {
        width: 100%;
    }
}



.register-container-box {
    min-width: 1408px;
    // width: 80%;
    // height: 82%;
}
@primary-color: #1DA57A;