.formbtn {
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;

    &:hover {
        background-color: #3F59AE;
        color: #fff;
    }
}

.info_btn {


    font-family: PingFang SC;


    color: #FFFFFF;
    width: 120px;
    height: 40px;

    background: #105EBF;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 0px solid #3F59AE;
    border-radius: 2px;

    &:hover {

        background: #3A7ED2;
        color: #fff;
    }
}

.projectform {
    margin-left: 0px;
}

.followbtn {
    margin-bottom: 20px;
}

.addbtn {
    width: 90px;
    height: 34px;
    background: #3FB97C;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
    border: 0;
}

.numberOfpiles {
    height: 31px;
    line-height: 31px;
    text-align: center;
    margin: auto;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
}

.space_botton .ant-form-item {
    margin-bottom: 12px;
}

.wwww {
    width: 200px;
}

.space_botton label {
    text-align: right;
    margin-right: 0px;
    display: flex;
    justify-content: end;
}
// .Lamination .ant-radio{
// width: 22px;
// height: 22px;
// }
.atoms_Row_span label{
    height: 42px !important;
}
@primary-color: #1DA57A;