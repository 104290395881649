 .blueClass .ant-input{
    border: 1px solid #3F59AE;
}
.blueClass .ant-input:hover {
    border-color: #3F59AE!important;
}

.redClass .ant-input{
    border: 1px solid #E60505;
}
.redClass .ant-input:hover {
    border-color: #E60505!important;
}