.exposebtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
}
.formbtnleft{
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;
    &:hover {
        background-color: #fff;
        color: #374C92;
        border: 1px solid #374C92;
        cursor:pointer;
    }

}
.formbtnright{
    width: 120px;
    height: 40px;
    background-color: #105EBF;
    // background-color: #FFFFFFFF;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #105EBF;
    border-radius: 2px;
    margin: 0 28px;
    &:hover {
        background-color: #3A7ED2;
        border: 1px solid #3A7ED2;
        color: #fff;
        cursor:pointer;
    }
}




g.type-current>rect {
    fill: #1E9FFF;
}

g.type-success>rect {
    fill: green;
}

g.type-fail>rect {
    fill: red;
}

text {
    font-weight: 300;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serf;
    font-size: 14px;
}

.node rect {
    stroke: #999;
    fill: #fff;
    stroke-width: 1.5px;
}

.edgePath path {
    stroke: #333;
    stroke-width: 1.5px;
}

.modelbackground{
    // width: 1600px;
    // height: 900px;
    background: #F9F9FC;
    opacity: 1;
    margin: 50px 0;
}
@primary-color: #1DA57A;