.forget-container {
    width: 100%;
    // height: 562px;
    height: 100%;
    box-shadow: 0px 25px 60px rgba(9, 44, 99, 0.24);
    border-radius: 0px;
    // -webkit-app-region: drag;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4E7EC;
}

.forget-content {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    img {
        margin-top: 17px;
    }
}

.forget-logo-container {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
}

.forget-space {
    flex: 1 1 0;
}

.forget-header {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.forget-header-button-container {
    padding: 14px 15px 0px 0px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;

    img {
        width: 16px;
        height: 16px;
        margin: 7px 7px 0 7px;
    }
}

.forget-header-title {
    text-align: center;
    font-family: "Bold", sans-serif;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    height: 33px;
    margin-top: 100px;
    margin-left: -295px;

}



.forget-form {
    padding-top: 45px;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;

    .ant-input {
        height: 40px;
        width: 326px;
        font-size: 16px;
    }

    .ant-form-item-label>label {
        font-size: 16px;
        font-family: "Regular", sans-serif;
        color: #000000;
        height: 22px;
        line-height: 22px;
        margin-top: 9px;
        font-weight: 400;
        opacity: 1;
        display: inline-block;
        width: 80px;
        margin-right: 10px;
        text-align: right;
    }

    @media (max-width: 575px) {

        .ant-form .ant-form-item .ant-form-item-label,
        .ant-form .ant-form-item .ant-form-item-control {
            flex: 0 0 auto;
        }
    }

    .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
    .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
    .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
    .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
        top: 58%
    }

    .ant-form-item-explain.ant-form-item-explain-error {
        color: #E60505;
        height: 20px;
        line-height: 20px;
    }
}

.forget-button {
    height: 50px;
    width: 402px;
    margin-top: 58px;
    background: #3F59AE;
    box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    opacity: 1;
    border-radius: 2px;
    font-family: "PingFangRegular", sans-serif;
    font-weight: 400;
    font-size: 18px;

    &:hover {
        background: #4967C6;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }

    &:active {
        background: #374C92;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }
}


.forget-login {
    height: 50px;
    width: 402px;
    margin-top: 64px;
    background: #3F59AE;
    box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    opacity: 1;
    color: white;
    border-radius: 2px;
    font-family: "Regular", sans-serif;
    font-weight: 400;
    font-size: 18px;

    &:hover {
        background: #4967C6;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }

    &:active {
        background: #374C92;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }
}


.forget-container_fl {
    width: 55%;
   

    background: url(../images/login/login_fl_back.png) no-repeat;
    background-size: 100% 100%;
    padding: 34px;
    max-height: 655px;
    height: 655px;

}

.forget-container_fr {
    width: 45%;
    min-width: 416px;
    
    max-height: 774px;
    height: 655px;
    background-color: #ffff;

}

.forget-container_fl_box {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forget-container_fl_box_center h3 {
    height: 53px;
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 53px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
}

.forget-container_fl_top_img {
    width: 99px;
    height: 84px;

    img {
        width: 100%;
    }
}

.forget-container_fl_center_img {


    width: 354px;
    height: 400px;
    img {
        width: 100%;
    }
}


.forget-container-box {
    min-width: 1408px;
    // width: 80%;
    // height: 82%;
}
@primary-color: #1DA57A;