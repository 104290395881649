.measure_table{
    width: 45%;
}
table.tftable {
    width: 100%;
    border-width: 1px;
    border-color: #000;
    border-collapse: collapse;

    font-size: 16px;
    font-family:Bold;

    font-weight: bold;
    line-height: 18px;
    color: #33373D;
    opacity: 1;
}

table.tftable th {
    font-size: 14px;
    font-family: Regular;

    font-weight: bold;
    line-height: 18px;
    color: #33373D;
    opacity: 1;
    height: 52px;
    background: #E5EEF9;
    // opacity: 0.1;
    border-width: 1px;
    padding: 8px;
    text-align: center;
}

table.tftable tr {
    // background-color:#fff;
    height: 22px;
    font-size: 14px;
    font-family: Regular;
    font-weight: 400;
    line-height: 18px;
    color: #33373D;
    opacity: 1;
}

table.tftable td {
    font-size: 14px;
    // background-color:#fff;
    padding: 8px;

    // border-bottom: 1px solid #E4E7ED;
    opacity: 1;
    text-align: center;
    font-family: Regular !important;
    color: #33373D;

}
@primary-color: #1DA57A;