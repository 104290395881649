.lineChart{
    display: flex;
    justify-content: space-around;
    // width: 100%;
    width: 1100px;
    margin: 0 auto;
    margin-top: 70px;
}
.linelist{
    width: 12%;
    position: relative;
}
.lineblue{
    width: 100%;
    // height: 25px;
    background: #3F59AE;
    box-shadow: 0px 6px 10px rgba(45, 79, 190, 0.5);
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    // line-height: 25px;
    padding: 8px;
    text-align: center;
    color: #FFFFFF;
}
.toplineopcity{
    width: 100%;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    padding: 8px;
    text-align: center;
    background: #EFF3FF;
    border: 1px solid #3F59AE;
    box-shadow: 0px 6px 10px rgba(81, 115, 226, 0.3);
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 119px;

}
.lineopcity{
    width: 100%;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    padding: 8px;
    text-align: center;
    background: #EFF3FF;
    border: 1px solid #3F59AE;
    box-shadow: 0px 6px 10px rgba(81, 115, 226, 0.3);
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 160px;

}
.linebodybtn{
    width: 100%;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    padding: 8px;
    text-align: center;
    background: #EFF3FF;
    border: 1px solid #3F59AE;
    box-shadow: 0px 6px 10px rgba(81, 115, 226, 0.3);
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 10px;
}
.linebody{
    margin-top: 92px;
}
.linehuxi{
    margin-top: 28px;
}
.linewater{
    margin-top: 30px;
}
.linecontaner{
    margin-top: 92px;
    width: 100%;
    height: 400px;
    background: #F4F5FC;
    border: 1px dotted #3F59AE;
    box-shadow: 0px 6px 10px rgba(81, 115, 226, 0.15);
    opacity: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.conbtn{
    width: 58%;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    padding: 8px;
    text-align: center;
    background: #EFF3FF;
    border: 1px solid #3F59AE;
    box-shadow: 0px 6px 10px rgba(81, 115, 226, 0.3);
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 10px;
}
.lineno{
    margin-bottom: 30px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.chart{
    position: absolute;
    top: 186px;
    left: 100%;
    width: 233%;
}
.kuosan1{
    position: absolute;
    top: 368px;
    left: 100%;
    width: 233%;
}
.kuosan2{
    position: absolute;
    top: 573px;
    left: 100%;
    width: 233%;
}
.yangchen{
    position: absolute;
    top: 203px;
    left: 100%;
    width: 236%;
}
.zhijie{
    position: absolute;
    top: 168px;
    left: 100%;
    width: 233%;
}
.huifa{
    position: absolute;
    top: 402px;
    left: 100%;
    width: 233%;
}

//左侧箭头

.box {
    height: 1px;
    background-color: #3F59AE;
    border-radius: 3.25px;
    position: absolute;
    z-index: 1;
    transform-origin:0 0;
}
.box .triangle {
    width: 0;
    height: 0;
    position: absolute;
    z-index: 1;
    border-width: 6px;
    border-style: solid;
    border-color: transparent;

}
.box .triangle.right {
    border-left-color: #3F59AE;
    right: -12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.turang1{
    top: 182px;
    left: 100%;
    transform: rotate(-15deg);
    width: 64%;
}
.turang2{
    top: 182px;
    left: 100%;
    transform: rotate(15deg);
    width: 64%;
}
.turang3{
    top: 384px;
    left: 100%;
    transform: rotate(-50deg);
    width: 97%;
}
.turang4{
    top: 384px;
    left: 100%;
    transform: rotate(-25deg);
    width: 69%;
}
.turang5{
    top: 384px;
    left: 100%;
    transform: rotate(23deg);
    width: 70%;
}
.turang6{
    top: 384px;
    left: 100%;
    transform: rotate(54deg);
    width: 108%;
}
.turang7{
    top: 587px;
    left: 100%;
    transform: rotate(0deg);
    width: 62%;
}

.zongjie1{
    top: 155px;
    left: 100%;
    transform: rotate(0deg);
    width: 62%;
}
.zongjie2{
    top: 208px;
    left: 100%;
    transform: rotate(-25deg);
    width: 69%;
}
.zongjie3{
    top: 279px;
    left: 100%;
    transform: rotate(-47deg);
    width: 92%;
}
.zongjie4{
    top: 346px;
    left: 100%;
    transform: rotate(-15deg);
    width: 87%;
}
.zongjie5{
    top: 424px;
    left: 100%;
    transform: rotate(-38deg);
    width: 106%;
}
.zongjie6{
    top: 424px;
    left: 100%;
    transform: rotate(-10deg);
    width: 85%;
}
.zongjie7{
    top: 501px;
    left: 100%;
    transform: rotate(
-53deg);
    width: 140%;
}
.zongjie8{
    top: 501px;
    left: 100%;
    transform: rotate(-36deg);
    width: 104%;
}
.zongjie9{
    top: 587px;
    left: 100%;
    transform: rotate(-45deg);
    width: 89%;
}
@primary-color: #1DA57A;