.win-drag {
    -webkit-app-region: drag;
    z-index: 1000;
}
.win-no-drag {
    -webkit-app-region: no-drag;
}

.ant-modal-header{
    background-color: #105EBF;
}
.ant-modal-title{
    color: #fff;
}
.ant-modal-body{
    padding: 32px;
}
.questionbettwen{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.questionbright{

}
.questiontitle{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000;
}
.email{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000;
    margin-right: 20px;
}
.emailnum{
    color: #002BFF;
}
.fankui{
    margin-top: 10px;
}
.fankuititle{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    opacity: 1;
    margin: 10px 0;
}
.twotop{
    margin-top: 30px;
}
.isheader .ant-input{
    height: 200px!important;
}
.ant-modal-footer{
    border-top: 0;
}
.ant-btn-primary{
    background: #105EBF;
    border-color: #105EBF;
}
.ant-btn-primary:hover{
    background: #3A7ED2;
    border-color: #3A7ED2;
}
.ant-modal-close{
    color: #fff;
}
.account{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.bangding{
    width: 80px;
    height: 30px;
    background: rgba(0, 141, 235, 0.16);
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #008DEB;
    text-align: center;
    line-height: 30px;
    margin-left: 15px;
}
.yibangding{
    width: 80px;
    height: 30px;
    background: #EDEDED;
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    line-height: 30px;
    margin-left: 23px;
}
.accountimg{
    width: 38px;
    height: 38px;
}
.Formbtn .ant-btn{
    width: 100%;
    cursor: pointer;
}
.headbettwen{
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
}
.pointerhand{
    cursor: pointer;
}
.headerColor{
    color: #fff;
    margin: 0 5px;
}
.homepage-system-container{
    margin-right: 20px;
}
.dropdown{
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.bettenspace{
    display:flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    background-color: #fff;
    border-bottom: 1px solid #E8E8E8;
}
.mytabs{
    width: 50%;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    opacity: 1;
    text-align: center;
    /* background-color: red; */

}
.dropdownlist{
    padding: 10px 20px;
}
.itemlist{
    height: 52px;
    line-height: 52px;
}
.activeTabs{
    background-color: #008DEB;
}
.projectcenter{
    /* text-align: center; */
    /* font-weight: bold; */
    /* font-size: 25px; */
}

body::-webkit-scrollbar {
    /* display: none; Chrome Safari */
}
  
  body {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    /* overflow-y: hidden; */
    /* -webkit-app-region: drag; */
  }
  .ant-layout-header{
      padding: 0;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before, .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before{
    background: #105EBF;
  }
  .ant-tree .ant-tree-node-content-wrapper{
      height: 40px;
      line-height: 40px;
  }
  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle{
    line-height: 40px;
  }

  .ant-tree-switcher{
    line-height: 40px;
  }

  /* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #3F59AE!important;
  } */
  .ant-tabs-ink-bar{
      background: #3F59AE!important;
  }

  .ant-input:focus, .ant-input-focused{
    border-color: #3F59AE;
    box-shadow: none;
  }
  .ant-input:hover{
    border-color: #3F59AE!important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color: #3F59AE!important;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    border-color: #3F59AE!important;
    box-shadow: none!important;
  }
  .ant-tabs-tab:hover{
      color: #3F59AE!important;
  }
  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active{
    color: #3F59AE!important;
  }
  .ant-pagination-item-active{
    border-color: #3F59AE!important;
  }
  .ant-pagination-item-active a{
    color: #3F59AE!important;
  }
  .ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover{
    color: #3F59AE!important;
  }
  .ant-pagination-item:focus-visible, .ant-pagination-item:hover{
    border-color: #3F59AE!important;
  }

 thead tr .follow-class {
    color: #E60505;       
    /* background-color: #1890ff !important;       */
  }
  .ant-dropdown-open{
      /* margin-top: -20px; */
  }
  .ant-tabs-nav-wrap .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
    margin: 0;
    padding: 8px 16px;
    background: #fafafa;
border: 1px solid #f0f0f0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
    background: #105EBF;
    /* color: #fff; */
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
      color: #fff!important;
  }
.TabsClass{
    margin-top: 10px!important;
}
.headeImg{
    width: 16px;
}

.resetOverlay{
    top: 47px !important;
}
.eeeeeeeee{
    position: relative;
}
.headbettwen{
    font-family: Regular;

}