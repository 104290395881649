.exposebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
}

.formbtnleft {
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;

    &:hover {
        background-color: #fff;
        color: #374C92;
        border: 1px solid #374C92;
        cursor: pointer;
    }

}

.flexcontent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.content {
    // width: 25%;
}

.rowclass {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.Cancer_Render  .ant-t.ant-table-tbody > tr > td {
    padding: 16px 16px 16px 16px !important;
}
@primary-color: #1DA57A;