// .dashboard{
//     -webkit-app-region: drag;
// }




.ant-layout{
    // min-height: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F6F6F6;
}


.db-header{
    height: 70px;
    width: 100%;
    background: #105EBF;
    box-shadow: 0px 4px 6px rgba(16,94,191,0.45);
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    // -webkit-app-region: drag;
    // padding: 0 0 0 26px;
  font-family: Regular;
   
}
:global(.ant-layout-header){
    padding: 0 20px!important;
}

.db-header-logo{
    display: inline-block;
    margin-left: 20px;
    // flex: 1 1 0%;
    img{
        margin: 9px 11px 0;
    }
}
.db-header-bg{
    display: inline-block;
    flex: 1 1 0%;
    margin-left: 15px;
    margin-top: 12px;
    margin-right: 9px;
    font-family: "PingFangBold", sans-serif;
    font-size: 32px;
    height: 45px;
    line-height: 45px;
    color: #FFFFFF;
}
.db-header-sm{
    display: inline-block;
    flex: 1 1 0%;
    font-family: "PingFangRegular", sans-serif;
    font-size: 18px;
    height: 25px;
    line-height: 25px;
    color: #FFFFFF;
    margin-top: 29px;
}

.homepage-screen-control-container{
    display: flex;
    flex-direction: row;
    border-left: 1px solid #FFFFFF;
    margin: 21px 16px 1px 0;
    padding-left: 26px;
    height: 25px;
    img{
        margin:4px 7px;
    }
}

.site-layout-background{
    background: #FFFFFF;
    // -webkit-app-region: drag;
}
.ant-tree-treenode{
    font-family: Regular;
    
}
.db-header-bg{
    font-family: Regular;

}
@primary-color: #1DA57A;