.exposeContent {
    padding: 24px;
}

.exposeRoad {
    // width: 480px;
    // height: 400px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 23px rgba(36, 46, 76, 0.2);
    padding: 15px;
    // margin: 100px;
    margin-right: 10px;
}

.report-img {
    padding-left: 30px;
}

.widthImg {
    // width: 450px;
    width: 100%;
    // height: 260px;
}

.reportMarign {
    // margin: 20px;pa
    padding: 30px;
}

.exposeTitle {
    padding: 18px 0;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.85);
}

.Select {
    width: 90%;
}

.exposeactive {
    border: 1px solid #3F59AE;
    box-shadow: 0px 2px 23px rgba(63, 89, 174, 0.5);

    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    margin-right: 10px;
}

.exposebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
}

.formbtnleft {
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;

    &:hover {
        background-color: #fff;
        color: #374C92;
        border: 1px solid #374C92;
        cursor: pointer;
    }

}

.formbtnright {
    width: 120px;
    height: 40px;
    background-color: #105EBF;
    // background-color: #FFFFFFFF;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #105EBF;
    border-radius: 2px;
    margin: 0 28px;

    &:hover {
        background-color: #3A7ED2;
        border: 1px solid #3A7ED2;
        color: #fff;
        cursor: pointer;
    }
}

.report {
    // width: 100%;
    background: #F8F9FC;
    box-shadow: 6px 6px 23px rgba(8, 15, 39, 0);
    opacity: 1;
    // min-width: 1100px;
    // background-size:1100px;
    // padding: 30px;
    // height: 100px;
}

.area-title {
    margin-bottom: 28px;
}



.LineChart-title {
    margin-bottom: 28px;
    padding: 0 30px;
}

// 第一层次
.report-title {
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #105EBF !important;
    opacity: 1;
    margin-top: 65px;
}

.report-time {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    opacity: 1;
    text-align: center;
    margin-top: 30px;
}

.area-top {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
}

.area-top:before {
    content: '';
    /*CSS伪类用法*/
    position: absolute;
    /*定位背景横线的位置*/
    top: -10px;
    margin-top: -8px;
    left: 0;
    width: 38px;
    height: 4px;
    background: #105EBF;
    opacity: 1;
    opacity: 1;
    border-radius: 2px;
}

.column-money {
    background-color: #E2E6F3;
    border: 1px solid #000;
}

.report-content {
    margin-top: 60px;
    padding: 0 30px;
}

.LineChart-content {
    margin-top: 60px;
}

.report .report-content table.tfhead {
    font-size: 14px;
    color: #000000;
    width: 100%;
    border-width: 1px;
    border-color: #000;
    border-collapse: collapse;
}

.report .report-content table.tfhead th {
    font-size: 14px;
    background-color: #E5EEF9;
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #000;
    text-align: center;
    font-family: Bold;
}

table.tfhead tr {
    // background-color:#fff;
}

table.tfhead td {
    font-size: 14px;
    border-width: 1px;
    padding: 10px 5px;
    border-style: solid;
    border-color: #000;
    text-align: center;
    font-family: Regular;

}

.report-btn {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    // margin-top: 92px;
    padding-bottom: 100px;

    img {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin: 0, 20px, 0, 20px;
        padding: 0, 20px, 0, 20px;
        ;

    }

    span {
        margin-left: 10px;
    }
}

.reportPdfContent {
    width: 100%;
    height: 100%;
    padding-bottom: 92px;
    background-image: url('../../../assets/images/report/bg4.png');
    background-repeat: round;
    background-size: 100%;
}

// .background{
//     // width: 100%;
//     // height: 100%;
//     background-size: cover;
//     z-index: 10000;
//     background-image: url('../../../assets/images/homePage/logo.png');
//     background-repeat: repeat;
// }
.laststep {
    width: 120px;
    height: 40px;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 5px 10px;
}

.twolevel {
    width: 164px;
    height: 40px;
    background: #3FB97C;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 1;
    // text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
}

.pdf {
    width: 138px;
    height: 40px;
    background: #F2585F;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
    cursor: pointer;

    :hover {
        opacity: 0.8;

    }

    :visited {
        background: #F2484F;
    }
}


.consult {
    width: 138px;
    height: 40px;

    background: #3881D4;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
    cursor: pointer;

    :hover {}

    :visited {
        background: #2265B1;
    }
}



.consultP {
    height: 27px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.85);
    opacity: 1;
}

.consultP_txt {
    margin-top: 40px;
}

.charge {
    width: 138px;
    height: 40px;

    background: #105EBF;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
    cursor: pointer;
    position: relative;

    :hover {
        opacity: 1;


    }

    :visited {
        background: #0352B4;
    }

    img {
        width: 16px;
        height: 16px;
    }
}



.word {
    width: 149px;
    height: 40px;
    background: #558FF2;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
}

.report-line {
    position: relative;
    // padding: 10px;
}

.report-line:after {
    // width: 100%;
    // height: 0px;
    // border: 1px solid #E9E9E9;
    // opacity: 1;

    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 200%;
    height: 200%;
    border: 1px solid #E9E9E9;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(.5, .5);
    transform: scale(.5, .5);
    // border-radius: 10px;
}


.watermark {
    opacity: 0.4;
    position: fixed;
    height: 45px;
    width: 160px;
    transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.watermark p {
    color: #dcdcdc;
}

.pay {
    width: 61px;
    height: 26px;
    display: block;
    background: linear-gradient(217deg, #FFD639 0%, #FFA91A 100%);
    box-shadow: 0px 1px 5px rgba(97, 52, 0, 0.34);
    position: absolute;
    right: -15px;
    top: -15px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: bold;
    color: #7B4600;
    font-size: 14px;
    line-height: 26px;
    // z-index: 555;
}

// .pay:after {

//     content: " ";

//     display: block;

//     position: relative;

//     top: -27px;

//     left: -11px;

//     width: 20px;

//     height: 20px;
//     transform: rotate(90deg);

//     background: -webkit-linear-gradient(transparent 0%, transparent 0%),

//             -webkit-linear-gradient(135deg, transparent 33.33%, transparent 33.33%) 0 0%,

//             transparent -webkit-linear-gradient(45deg, transparent 33.33%, #e6a031 33.33%) 0 0%;

//     background-repeat: repeat-x;


//     background-size: 0px 100%, 9px 27px, 6px 18px;
// z-index: 1;
// }
.ant-divider-horizontal.ant-divider-with-text {

    height: 40px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 80px;
    color: #000000;
    opacity: 1;
}

.orientation_Margin {
    height: 50px;

    .ant-divider-inner-text {
        padding-left: 0;
    }
}

.report-content .orientationMargin:before {
    width: 0%;
}

.area-btm {
    text-transform: uppercase;

    font-size: 12px;
    font-family: Regular;
    font-weight: 500 !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.45);
    opacity: 1;
}
.ReportTable_marght .tftable{
    margin-bottom: 40px;
}
@primary-color: #1DA57A;