table.tftable {
    font-size:12px;
    font-family: Regular;
    color:#000000;
    width:100%;
    border-width: 1px;
    border-color: #000;
    border-collapse: collapse;
}
table.tftable th {
    font-size:12px;
    background-color:#E2E6F3;
    // opacity: 0.1;
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #000;
    text-align:center;
    font-family: Regular;

}
table.tftable tr {
    // background-color:#fff;
}
table.tftable td {
    font-size:12px;
    // background-color:#fff;
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #000;
    text-align:center;
    font-family: Regular;
}
.dr table.tftable td:last-child {
    font-weight: 900;
    font-size: 16px !important;
    padding: 0px;
    
}
@primary-color: #1DA57A;